import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`A customer’s location.`}</p>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">type</GQLKeyword> Location <GQLOpen mdxType="GQLOpen" /></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># ID of location.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">id</GQLName>: <GQLLink href="/ecdn-api-scalars/ID" mdxType="GQLLink">ID</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Name of location.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">name</GQLName>: <GQLLink href="/ecdn-api-scalars/String" mdxType="GQLLink">String</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Number of servers in location.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">numberOfServers</GQLName>: <GQLLink href="/ecdn-api-scalars/Int" mdxType="GQLLink">Int</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Servers in location.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">servers</GQLName>: [<GQLLink href="/ecdn-api-objects/Server" mdxType="GQLLink">Server</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]<GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Parent location or region of location.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">parent</GQLName>: <GQLLink href="/ecdn-api-unions/LocationsParent" mdxType="GQLLink">LocationsParent</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># (direct) Child locations of location.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">children</GQLName>: [<GQLLink href="/ecdn-api-objects/Location" mdxType="GQLLink">Location</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]<GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Depth of (deepest possible) child location chain (starting from this location).</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># * A location without any children has children a depth of 0.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># * A location with a child that doesn't have any children has a depth of 1.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># * A location that has at least one child with children has a depth of 2.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">childrenDepth</GQLName>: <GQLLink href="/ecdn-api-scalars/Int" mdxType="GQLLink">Int</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># The maximum children depth this location can have. A maximum of 3 locations can be chained</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># together. Therefore if a location:</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># * Is a top-level one, the maximum children depth is 2</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment">#   (it can have child locations with children).</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># * Has a top-level location as parent: the maximum children depth is 1</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment">#   (can have children, but they cannot have additional children).</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># * Has a parent location with a parent location: the maximum children depth is 0</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment">#   (cannot have children, would exceed the overall -3- depth limit).</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">childrenDepthLimit</GQLName>: <GQLLink href="/ecdn-api-scalars/Int" mdxType="GQLLink">Int</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># External IP ranges of location.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">externalRanges</GQLName>: [<GQLLink href="/ecdn-api-scalars/IPv4Range" mdxType="GQLLink">IPv4Range</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]<GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Whether external ranges are inherited from parent location.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">inheritExternalRanges</GQLName>: <GQLLink href="/ecdn-api-scalars/Boolean" mdxType="GQLLink">Boolean</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Restriction for client connections. Null if there aren't any restrictions.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">clientRestriction</GQLName>: <GQLLink href="/ecdn-api-objects/ClientRestriction" mdxType="GQLLink">ClientRestriction</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Whether clients can connect to servers in this location or not.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">clientConnectionsEnabled</GQLName>: <GQLLink href="/ecdn-api-scalars/Boolean" mdxType="GQLLink">Boolean</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Whether clients can fall back to external CDNs or not.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">cdnFallbackEnabled</GQLName>: <GQLLink href="/ecdn-api-scalars/Boolean" mdxType="GQLLink">Boolean</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Whether management traffic of servers in location are proxied through parent servers.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Video traffic is proxied through parent servers in all cases.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Management traffic can only be proxied through ECDN servers (i.e: not through midgress),</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># therefore this can only be enabled in child locations.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">parentProxyEnabled</GQLName>: <GQLLink href="/ecdn-api-scalars/Boolean" mdxType="GQLLink">Boolean</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># What time was this location created and by whom.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">created</GQLName>: <GQLLink href="/ecdn-api-objects/RecordedAction" mdxType="GQLLink">RecordedAction</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Last time this location was modified and by whom.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">modified</GQLName>: <GQLLink href="/ecdn-api-objects/RecordedAction" mdxType="GQLLink">RecordedAction</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
      <GQLLine mdxType="GQLLine"><GQLClose mdxType="GQLClose" /></GQLLine>
    </GQLCodeSnippet>
    <h2>{`Fields`}</h2>
    <h3>{`id: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/ID"
      }}>{`ID`}</a>{`!`}</h3>
    <p>{`ID of location.`}</p>
    <h3>{`name: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/String"
      }}>{`String`}</a>{`!`}</h3>
    <p>{`Name of location.`}</p>
    <h3>{`numberOfServers: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Int"
      }}>{`Int`}</a>{`!`}</h3>
    <p>{`Number of servers in location.`}</p>
    <h3>{`servers: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/Server"
      }}>{`Server`}</a>{`!]!`}</h3>
    <p>{`Servers in location.`}</p>
    <h3>{`parent: `}<a parentName="h3" {...{
        "href": "/ecdn-api-unions/LocationsParent"
      }}>{`LocationsParent`}</a>{`!`}</h3>
    <p>{`Parent location or region of location.`}</p>
    <h3>{`children: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/Location"
      }}>{`Location`}</a>{`!]!`}</h3>
    <p>{`(direct) Child locations of location.`}</p>
    <h3>{`childrenDepth: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Int"
      }}>{`Int`}</a>{`!`}</h3>
    <p>{`Depth of (deepest possible) child location chain (starting from this location).`}</p>
    <ul>
      <li parentName="ul">{`A location without any children has children a depth of 0.`}</li>
      <li parentName="ul">{`A location with a child that doesn’t have any children has a depth of 1.`}</li>
      <li parentName="ul">{`A location that has at least one child with children has a depth of 2.`}</li>
    </ul>
    <h3>{`childrenDepthLimit: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Int"
      }}>{`Int`}</a>{`!`}</h3>
    <p>{`The maximum children depth this location can have. A maximum of 3 locations can be chained
together. Therefore if a location:`}</p>
    <ul>
      <li parentName="ul">{`Is a top-level one, the maximum children depth is 2
(it can have child locations with children).`}</li>
      <li parentName="ul">{`Has a top-level location as parent: the maximum children depth is 1
(can have children, but they cannot have additional children).`}</li>
      <li parentName="ul">{`Has a parent location with a parent location: the maximum children depth is 0
(cannot have children, would exceed the overall -3- depth limit).`}</li>
    </ul>
    <h3>{`externalRanges: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/IPv4Range"
      }}>{`IPv4Range`}</a>{`!]!`}</h3>
    <p>{`External IP ranges of location.`}</p>
    <h3>{`inheritExternalRanges: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Boolean"
      }}>{`Boolean`}</a>{`!`}</h3>
    <p>{`Whether external ranges are inherited from parent location.`}</p>
    <h3>{`clientRestriction: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/ClientRestriction"
      }}>{`ClientRestriction`}</a></h3>
    <p>{`Restriction for client connections. Null if there aren’t any restrictions.`}</p>
    <h3>{`clientConnectionsEnabled: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Boolean"
      }}>{`Boolean`}</a>{`!`}</h3>
    <p>{`Whether clients can connect to servers in this location or not.`}</p>
    <h3>{`cdnFallbackEnabled: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Boolean"
      }}>{`Boolean`}</a>{`!`}</h3>
    <p>{`Whether clients can fall back to external CDNs or not.`}</p>
    <h3>{`parentProxyEnabled: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Boolean"
      }}>{`Boolean`}</a>{`!`}</h3>
    <p>{`Whether management traffic of servers in location are proxied through parent servers.
Video traffic is proxied through parent servers in all cases.
Management traffic can only be proxied through ECDN servers (i.e: not through midgress),
therefore this can only be enabled in child locations.`}</p>
    <h3>{`created: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/RecordedAction"
      }}>{`RecordedAction`}</a>{`!`}</h3>
    <p>{`What time was this location created and by whom.`}</p>
    <h3>{`modified: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/RecordedAction"
      }}>{`RecordedAction`}</a>{`!`}</h3>
    <p>{`Last time this location was modified and by whom.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      